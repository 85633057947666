import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "login",
        component: () => import("../components/login.vue"),
        meta: {
            title: '登录'
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../components/home.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/home1",
        name: "home1",
        component: () => import("../components/tab/home1.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/BakingParameters",
        name: "BakingParameters",
        component: () => import("../components/tab/BakingParameters.vue"),
        meta: {
            title: '烘烤参数'
        }
    },
    {
        path: "/AutomaticBaking",
        name: "AutomaticBaking",
        component: () => import("../components/tab/AutomaticBaking.vue"),
        meta: {
            title: '烘烤曲线'
        }
    },
    {
        path: "/CurveBaking",
        name: "CurveBaking",
        component: () => import("../components/tab/CurveBaking.vue"),
        meta: {
            title: '曲线烘烤'
        }
    },
    {
        path: "/BakingRecords",
        name: "BakingRecords",
        component: () => import("../components/tab/BakingRecords.vue"),
        meta: {
            title: '烘烤记录'
        }
    },
    {
        path: "/BakingRecords1",
        name: "BakingRecords1",
        component: () => import("../components/tab/BakingRecords1.vue"),
        meta: {
            title: '烘烤记录'
        }
    },
    {
        path: "/BakingCurve",
        name: "BakingCurve",
        component: () => import("../components/tab/BakingCurve.vue"),
        meta: {
            title: '烘烤记录'
        }
    },
    {
        path: "/heat",
        name: "heat",
        component: () => import("../components/tab/heat.vue"),
        meta: {
            title: '热泵机组参数'
        }
    },
    {
        path: "/heat1",
        name: "heat1",
        component: () => import("../components/tab/heat1.vue"),
        meta: {
            title: '燃烧机参数'
        }
    },
    {
        path: "/heat2",
        name: "heat2",
        component: () => import("../components/tab/heat2.vue"),
        meta: {
            title: '其他设备参数'
        }
    },
    {
        path: "/faultList",
        name: "faultList",
        component: () => import("../components/tab/faultList.vue"),
        meta: {
            title: '故障列表'
        }
    },
    {
        path: "/History",
        name: "History",
        component: () => import("../components/tab/History.vue"),
        meta: {
            title: '历史记录'
        }
    },
    {
        path: "/Settings",
        name: "Settings",
        component: () => import("../components/tab/Settings.vue"),
        meta: {
            title: '系统设置'
        }
    },
    {
        path: "/RealImage",
        name: "RealImage",
        component: () => import("../components/tab/RealImage.vue"),
        meta: {
            title: '实时图像'
        }
    },
    {
        path: "/feedback",
        name: "feedback",
        component: () => import("../components/tab/feedback.vue"),
        meta: {
            title: '意见反馈'
        }
    }
];

const router = new VueRouter({
    routes
});

export default router;
<template>
	<div class="left">
    <!-- <div class="left_top">样本库管理平台</div> -->
    <el-menu class="el-menu-vertical-demo" background-color="#fff" text-color="#333" active-text-color="#333" @select="handleOpen" :collapse="isCollapse">
      <el-menu-item index="1" @click="sendSchoolName('RealImage','自动烘烤','自动烘烤',1,0)" v-if="!flag" :class="tabIndex == 1?'active_menu':''">
        <img src="../assets/images/left_img1.png" alt="" srcset="">
        <span slot="title" class="left_title">自动烘烤</span>
      </el-menu-item>
      <el-menu-item index="2" @click="sendSchoolName('CurveBaking','曲线烘烤','曲线烘烤',2,0)" v-if="!flag" :class="tabIndex == 2?'active_menu':''">
        <img src="../assets/images/left_img2.png" alt="" srcset="">
        <span slot="title" class="left_title">曲线烘烤</span>
      </el-menu-item>
      <el-menu-item index="3" @click="sendSchoolName('Settings','系统设置','系统设置',3,0)" v-if="!flag" :class="tabIndex == 3?'active_menu':''">
        <img src="../assets/images/left_img3.png" alt="" srcset="">
        <span slot="title" class="left_title">系统设置</span>
      </el-menu-item>
      <el-menu-item index="4" @click="sendSchoolName('heat1','燃烧机参数','燃烧机参数',4,0)" v-if="!flag" :class="tabIndex == 4?'active_menu':''">
        <img src="../assets/images/left_img4.png" alt="" srcset="">
        <span slot="title" class="left_title">燃烧机参数</span>
      </el-menu-item>
      <el-menu-item index="5" @click="sendSchoolName('heat','热泵机参数','热泵机参数',5,0)" v-if="!flag" :class="tabIndex == 5?'active_menu':''">
        <img src="../assets/images/left_img5.png" alt="" srcset="">
        <span slot="title" class="left_title">热泵机组参数</span>
      </el-menu-item>
      <el-menu-item index="6" @click="sendSchoolName('heat2','其他设备参数','其他设备参数',6,0)" v-if="!flag" :class="tabIndex == 6?'active_menu':''">
        <img src="../assets/images/left_img6.png" alt="" srcset="">
        <span slot="title" class="left_title">其他设备参数</span>
      </el-menu-item>
      <el-menu-item index="7" @click="sendSchoolName('BakingParameters','烘烤参数','烘烤参数',7,0)" v-if="!flag" :class="tabIndex == 7?'active_menu':''">
        <img src="../assets/images/left_img12.png" alt="" srcset="" style="width: 30px;height: 30px;">
        <span slot="title" class="left_title">烘烤参数</span>
      </el-menu-item>
      <el-menu-item index="8" @click="sendSchoolName('home1','集中监控','集中监控',8,0)" v-if="flag" :class="tabIndex == 8?'active_menu':''">
        <img src="../assets/images/left_img8.png" alt="" srcset="">
        <span slot="title" class="left_title">集中监控</span>
      </el-menu-item>
      <!-- <el-menu-item index="9" @click="sendSchoolName('left_tab6','报警列表','报警列表',9,0)">
        <img src="../assets/images/left_img9.png" alt="" srcset="">
        <span slot="title" class="left_title">报警列表</span>
      </el-menu-item> -->
      <!-- <el-menu-item index="10" @click="sendSchoolName('faultList','故障列表','故障列表',10,0)" v-if="flag">
        <img src="../assets/images/left_img10.png" alt="" srcset="">
        <span slot="title" class="left_title">故障列表</span>
      </el-menu-item> -->
      <el-menu-item index="9" @click="sendSchoolName('feedback','意见反馈','意见反馈',9,0)" v-if="flag" :class="tabIndex == 9?'active_menu':''">
        <img src="../assets/images/left_img11.png" alt="" srcset="">
        <span slot="title" class="left_title">意见反馈</span>
      </el-menu-item>
      <el-menu-item index="10" @click="sendSchoolName('History','历史记录','历史记录',10,0)" v-if="!flag" :class="tabIndex == 10?'active_menu':''">
        <img src="../assets/images/left_img13.png" alt="" srcset="" style="width: 30px;height: 30px;">
        <span slot="title" class="left_title">历史记录</span>
      </el-menu-item>
      <!-- <el-submenu index="7">
        <template slot="title">
          <img src="../assets/images/left_img7.png" alt="" srcset="">
          <span slot="title" class="left_title">烘烤参数</span>
        </template>
      </el-submenu> -->
      <!-- <el-submenu index="8" v-if="userInfo.rule_type != 0">
        <template slot="title">
          <img src="../assets/images/left_img8.png" alt="" srcset="">
          <span slot="title" class="left_title">基础设置</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="8-1" @click="sendSchoolName('AddItem','添加项目','添加项目','8-1',0)">添加项目</el-menu-item>
          <el-menu-item index="8-2" @click="sendSchoolName('AddCompany','添加公司','添加公司','8-1',0)" v-if="userInfo.rule_type == 2">添加公司</el-menu-item>
          <el-menu-item index="8-3" @click="sendSchoolName('AddVolume','添加体积','添加体积','8-1',0)">添加体积</el-menu-item>
          <el-menu-item index="8-4" @click="sendSchoolName('AddLocation','添加储存地点','添加储存地点','8-1',0)" >添加储存地点</el-menu-item>
          <el-menu-item index="8-5" @click="sendSchoolName('AddSampleType','添加样本类型','添加样本类型','8-1',0)">添加样本类型</el-menu-item>
          <el-menu-item index="8-6" @click="sendSchoolName('AddCollectionlocation','添加采集地点','添加采集地点','8-1',0)">添加采集地点</el-menu-item>
          <el-menu-item index="8-7" @click="sendSchoolName('AddSampleClassification','添加样本中转站分类','添加样本中转站分类','8-1',0)">添加样本中转站分类</el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
    </el-menu>
	</div>  
</template>

<script>
  import vmson from '@/components/emptyVue.js'
  export default {
    props:['childEvent'],
    components:{
      vmson
    },
    data() {
      return{
        isCollapse: false,
        name:'left_tab1',
        tabIndex:1,
        userInfo:'',
        flag:true
      };
    },
    watch:{
      $route(to,from){
        console.log(to,333333333)
        if(to.meta.title == '首页' || to.meta.title == '故障列表'|| to.meta.title == '意见反馈'){
          this.flag = true
        }else{
          this.flag = false
        }
        if(to.meta.title == '实时图像'){
          this.tabIndex = 1
        }
        if(to.meta.title == '曲线烘烤' || to.meta.title == '烘烤记录1'){
          this.tabIndex = 2
        }
      }
    },
    mounted() {
      console.log(this.$route.meta.title)
      if(this.$route.meta.title == '首页' || this.$route.meta.title == '故障列表'|| this.$route.meta.title == '意见反馈'){
          this.flag = true
      }else{
          this.flag = false
      }
      if(sessionStorage.getItem('userinfo')){
        this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
      }

      if(this.$route.meta.title == '实时图像'){
          this.tabIndex = ''
        }else if(this.$route.meta.title == '曲线烘烤'){
          this.tabIndex = 2
        }else if(this.$route.meta.title == '系统设置'){
          this.tabIndex = 3
        }else if(this.$route.meta.title == '燃烧机参数'){
          this.tabIndex = 4
        }else if(this.$route.meta.title == '热泵机参数'){
          this.tabIndex = 5
        }else if(this.$route.meta.title == '其他设备参数'){
          this.tabIndex = 6
        }else if(this.$route.meta.title == '烘烤参数'){
          this.tabIndex = 7
        }else if(this.$route.meta.title == '集中监控'||this.$route.meta.title == '首页'){
          this.tabIndex = 8
        }else if(this.$route.meta.title == '意见反馈'){
          this.tabIndex = 9
        }else if(this.$route.meta.title == '历史记录'){
          this.tabIndex = 10
        }else if(this.$route.meta.title == '烘烤记录1'){
          this.tabIndex = 2
        }
      // vmson.$on('left_tab',(options)=>{
      //   console.log(options)
      //   this.name = options.name
      //   this.tabIndex = options.num
      //   vmson.$emit('left_num', { name: options.name})
      // })
      // window.addEventListener('popstate', this.popStateHandle, false)
    },
    // onUnmounted(){
    //   window.removeEventListener('popstate', this.popStateHandle)
    // },
    methods: {
      // popStateHandle() {
      //   this.$router.push('./home')
      // },
      sendSchoolName(name,pageName,subordinateName,index,num){
        if(index == 1 || index == 2){
          sessionStorage.setItem('leftType', index);
        }
        this.$router.push({path:name})
        vmson.$emit('left_num', { name: name,pageName:pageName,subordinateName:subordinateName,index:index,num:num})
      },
      handleOpen(key, keyPath) {
        this.tabIndex = key
        console.log(key, keyPath);
      },
    },
  };
  </script>

<style scoped lang="scss">
.left{
  width: 200px;
  height: 100%;
  background: #fff;
  transition: width .28s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  text-align: left;
  padding-top: 86px;
  .el-menu{
    border-right: 0;
    padding-left: 10px;
  }
  .left_top{
    width: 210px;
    height: 66px;
    text-align: center;
    line-height: 66px;
    background: #fff;
    color: #fff;
    font-size: 24px;
  }
  .left_title{
    padding-left: 30px;
  }
}	
.active_menu{
  background-color: rgb(204,204,204)!important;
}
</style>
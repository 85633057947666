<template>
  <div id="app">
    <router-view></router-view>
    <HomeLeft v-if="$route.path !== '/'" />
    <HomeRight v-if="$route.path !== '/'" />
    <el-dialog :visible.sync="dialogVisible" width="520px">
      <div class="popup_box">
        <div class="popup_title">提示</div>
        <div class="popup_con">设备名称：{{ name }}</div>
        <div class="popup_con popup_con_p">报警信息：{{ msg }}</div>
        <div class="popup_footer">
          <div class="popup_btn" @click="devices_secure_fault">解除故障</div>
          <div class="popup_btn popup_btn_b" @click="confirmBtn">跳转设备</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vmson from '@/components/emptyVue.js'
import HomeLeft from '@/components/homeLeft.vue'
import HomeRight from '@/components/homeRight.vue'
export default {
  name: 'App',
  components: {
    HomeLeft,
    HomeRight,
    vmson
  },
  data() {
    return {
      dialogVisible: false,
      name: '',
      devices_id: '',
      msg: '',
      devicesData:'',
      bakingCondition:'',
      timer:null
    }
  },
  created() {
      // window.addEventListener("beforeunload",()=>{
      //   console.log(888)
      //   setTimeout(() => {
      //     this.$websocket.initWebSocket();
      //   }, 2000);
      // })
  },
  mounted() {
    // console.log(sessionStorage.getItem('scoketIndex'))
    // if(sessionStorage.getItem('scoketIndex') != ''){
    //   this.$websocket.initWebSocket();
    // }
    // let _this = this
    // _this.initWebSocket();
    // this.timer = setInterval(() => {
    //   console.log("开始---");
    //   _this.initWebSocket();
    // }, 5000);
    window.addEventListener("onmessageWS", this.getSocketData);
  },
  methods: {
    initWebSocket() {
      let str = sessionStorage.getItem('scoketIndex')
      console.log(str,111)
      if(str){
        console.log(33333)
        clearInterval(this.timer);
				this.timer = null
      }else{
        console.log(22222)
        this.$websocket.initWebSocket();
      }
    },
    getSocketData(res) {
      if (res.detail.data.devices_id) {
        this.name = res.detail.data.name
        this.bakingCondition = res.detail.data.bakingCondition
        this.devices_id = res.detail.data.devices_id
        this.msg = res.detail.data.msg
        this.devicesData = res.detail.data.data
        this.dialogVisible = true
      }
    },
    confirmBtn() {
      this.dialogVisible = false
      sessionStorage.setItem('deviceId', this.devices_id);
      sessionStorage.setItem('deviceData', JSON.stringify(this.devicesData));
      if(this.bakingCondition == 2){
        this.$router.push({ path: 'CurveBaking' })
        vmson.$emit('left_num', { name: 'CurveBaking', pageName: '曲线烘烤', subordinateName: '曲线烘烤', index: 2, num: 0 })
      }else{
        this.$router.push({ path: 'RealImage' })
        vmson.$emit('left_num', { name: 'RealImage', pageName: '自动烘烤', subordinateName: '自动烘烤', index: 1, num: 0 })
      }
    },
    devices_secure_fault() {
      this.request.post("/api/devices/devices_secure_fault", {
        id: this.devices_id
      }).then(res => {
        console.log(res)
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.dialogVisible = false
        } else {
          this.$message.error(res.msg);
        }
      })
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.content {
  width: calc(100vw - 200px);
  height: calc(100vh - 60px);
  /* overflow-y: scroll; */
  margin-left: 200px;
  margin-top: 60px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.popup_box {
  width: 480px;
  font-size: 20px;
}

.popup_title {
  text-align: center;
}

.popup_con {
  text-align: center;
  padding: 30px 0 20px;
}

.popup_con_p {
  padding: 0 0 50px;
}

.popup_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 10px;


}

.popup_btn {
  width: 180px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.popup_btn_b {
  background: #8EB0FF;
  border: 1px solid #8EB0FF;
  color: #fff;
}
</style>

import { Notification } from "element-ui";
// import { getToken } from "../utils/token";

var socket = null;//实例对象
var lockReconnect = false; //是否真正建立连接
var timeout = 20 * 1000; //20秒一次心跳
var timeoutObj = null; //心跳倒计时
var serverTimeoutObj = null; //服务心跳倒计时
var timeoutnum = null; //断开 重连倒计时

const initWebSocket = async () => {
  if ("WebSocket" in window) {
    const wsUrl = 'wss://yanye.yyhkai.com:24399?token=' + JSON.parse(sessionStorage.getItem('userinfo')).token;
    socket = new WebSocket(wsUrl);
    socket.onerror = webSocketOnError;
    socket.onmessage = webSocketOnMessage;
    socket.onclose = closeWebsocket;
    socket.onopen = openWebsocket;
  } else {
    Notification.error({
      title: "错误",
      message: "您的浏览器不支持websocket，请更换Chrome或者Firefox",
    });
  }
}

//建立连接
const openWebsocket = (e) => {
  start();
}

const start = () => {
  //开启心跳
  timeoutObj && clearTimeout(timeoutObj);
  serverTimeoutObj && clearTimeout(serverTimeoutObj);
  timeoutObj = setTimeout(function () {
    //这里发送一个心跳，后端收到后，返回一个心跳消息
    console.log(socket,'ping')
    if (socket.readyState == 1) {
      //如果连接正常
      socket.send("ping");
      // console.log('ping')
    } else {
      //否则重连
      reconnect();
    }
    // serverTimeoutObj = setTimeout(function () {
    //   //超时关闭
    //   socket.close();
    // }, 10000);
  }, timeout);
}

//重新连接
const reconnect = () => {
  console.log('重新连接中')
  if (lockReconnect) {
    return;
  }
  lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  timeoutnum && clearTimeout(timeoutnum);
  timeoutnum = setTimeout(function () {
    //新连接
    initWebSocket();
    lockReconnect = false;
  }, 1000);
}

//重置心跳
const reset = () => {
  //清除时间
  clearTimeout(timeoutObj);
  clearTimeout(serverTimeoutObj);
  //重启心跳
  start();
}

const sendWebsocket = (e) => {
  // console.log(e, `我发消息了`)
  if(socket == null){
    initWebSocket();
  }
  socket.send(e);
}

const webSocketOnError = (e) => {
  initWebSocket();
  reconnect();

}

//服务器返回的数据
const webSocketOnMessage = (e) => {
  //判断是否登录
  if (sessionStorage.getItem('userinfo')) {
    //window自定义事件[下面有说明]
    // window.dispatchEvent(
    //   new CustomEvent("myCustomEvent", {
    //     detail: {
    //       data: JSON.parse(e.data),
    //     },
    //   })
    // );
    // 创建一个自定义事件  
    let str = JSON.parse(e?.data)
    // console.log(str,1111)
    if(str.type == 'real_data' || str.type == 'devices_autobake' || str.type == 'devices_curvebake' || str.type == 'devices_systemparams' || str.type == 'devices_bakingparams' || str.type == 'devices_burnerparams' || str.type == 'devices_heatpumpunit' || str.type == 'devices_otherparams'){
      const myEvent = new CustomEvent('myCustomEvent', {
        detail: {
          message:str
        },
        bubbles: true,
        cancelable: true
      });
      // 触发自定义事件  
      window.dispatchEvent(myEvent);
    }else if(str.type == 'devices_infologimage'){
      const myEvent = new CustomEvent('myCustomEventImg', {
        detail: {
          message:str
        },
        bubbles: true,
        cancelable: true
      });
      // 触发自定义事件  
      window.dispatchEvent(myEvent);
    }
  }
  reset();
}

const closeWebsocket = (e) => {
  reconnect();
}

//断开连接
const close = () => {
  //WebSocket对象也有发送和关闭的两个方法，只需要在自定义方法中分别调用send()和close()即可实现。
  socket.close();
}
//具体问题具体分析,把需要用到的方法暴露出去
export default { initWebSocket, sendWebsocket, webSocketOnMessage, close };
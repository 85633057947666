<template>
  <div class="right">
    <!-- <div class="right_left"> -->
    <!-- <img src="../assets/images/menu_select.png" alt="" srcset=""> -->
    <!-- <div class="breadcrumb">
        <div class="breadcrumb_li" v-if="!listData">首页</div>
        <div class="breadcrumb_li" v-for="(item,index) in listData" @click="goPage(item,index)" v-else>
          <i class="el-icon-arrow-right" v-if="index != 0"></i>
          <span>{{item.subordinateName}}</span>
        </div>
      </div> -->
    <!-- </div> -->
    <div class="header_left">
      <el-popover placement="bottom-end" width="150" trigger="click" v-model="visible">
        <div class="right_ul">
          <!-- <div class="right_li" @mouseover="mouseOver1" @mouseleave="mouseLeave1" @click="dialogPassword = true">
            <img src="../assets/images/select_img1.png" alt="" srcset="" v-if="!list_flag1">
            <img src="../assets/images/select_img3.png" alt="" srcset="" v-if="list_flag1 == true">
            <div>修改密码</div>
          </div> -->
          <div class="right_li" @mouseover="mouseOver2" @mouseleave="mouseLeave2" @click="dialogClose = true">
            <img src="../assets/images/select_img2.png" alt="" srcset="" v-if="!list_flag2">
            <img src="../assets/images/select_img4.png" alt="" srcset="" v-if="list_flag2 == true">
            <div>退出登录</div>
          </div>
        </div>
        <div class="right_select" slot="reference">
          <img :src="$baseUrl + userInfo.avatar" alt="" srcset="">
          <div>{{ userInfo.username }}</div>
          <i class="el-icon-caret-bottom"></i>
        </div>
      </el-popover>
      <div class="header_flex" v-if="!flag">
        <div class="header_btn" @click="goHome">集中监控</div>
        <div>
          <div>
            <span>{{ deviceData.remarks }}</span>
            <span>设备编号：{{ deviceData.number_no }}</span>
            <span>姓名:{{ deviceData.user ? deviceData.user : '-' }}</span>
            <span>电话:{{ deviceData.phone ? deviceData.phone : '-' }}</span>
          </div>
          <div class="header_w">地址:
            <el-tooltip class="item" effect="dark" :content="deviceData.address" placement="top-start">
              <span>{{ deviceData.address }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="tab" v-if="!flag1">
      <div class="tab_li" v-for="(item, index) in tabData" :key="index"
        :class="tabIndex == index ? 'tab_li_active' : ''" @click="tabBtn(index)">
        <img :src="item.img" alt="" srcset="">
        <span>{{ item.title }}</span>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogPassword" :modal="false" width="500px">
      <div class="dialog_ul">
        <div class="dialog_li">
          <div class="dialog_li_label">新密码</div>
          <el-input type="password" v-model="newpassword" placeholder="请输入密码"></el-input>
        </div>
        <div class="dialog_li">
          <div class="dialog_li_label">确认密码</div>
          <el-input type="password" v-model="newpassword2" placeholder="请确认密码"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPassword = false">取 消</el-button>
        <el-button type="primary" @click="sumbitPwd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="退出登录" :visible.sync="dialogClose" :modal="false" width="500px">
      <span>请问是否确定要退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClose = false">取 消</el-button>
        <el-button type="primary" @click="closeBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vmson from '@/components/emptyVue.js'
// import { EventBus } from './emptyVue.js';
export default {
  components: {
    vmson
  },
  data() {
    return {
      visible: false,
      list_flag1: false,
      list_flag2: false,
      dialogPassword: false,
      dialogClose: false,
      userInfo: '',
      input: '',
      newpassword: '',
      newpassword2: '',
      name: '',
      index: '',
      num: '',
      pageName: '',
      subordinateName: '',
      listData: [],
      tabData: [{
        title: '实时图像',
        img: require('../assets/images/top_img5.png')
      }, {
        title: '烘烤曲线',
        img: require('../assets/images/top_img6.png')
      }, {
        title: '烘烤记录',
        img: require('../assets/images/top_img3.png')
      },
        // {
        //   title: '历史记录',
        //   img: require('../assets/images/top_img4.png')
        // }
      ],
      tabIndex: 0,
      flag: '',
      flag1: '',
      deviceData: {}
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.meta.title,6666666)
      if (to.meta.title == '首页' || to.meta.title == '故障列表' || to.meta.title == '意见反馈') {
        this.flag = true
        this.flag1 = true
      } else {
        if (to.meta.title == '系统设置' || to.meta.title == '燃烧机参数' || to.meta.title == '热泵机组参数' ||
          to.meta.title == '其他设备参数' || to.meta.title == '烘烤参数' || to.meta.title == '历史记录') {
          this.flag1 = true
        } else {
          this.flag1 = false
        }
        this.flag = false
      }
      if (sessionStorage.getItem('deviceData')) {
        this.deviceData = JSON.parse(sessionStorage.getItem('deviceData'))
      }
      if (to.meta.title == '实时图像') {
        this.tabIndex = 0
      } else if (to.meta.title == '烘烤曲线') {
        this.tabIndex = 1
      } else if (to.meta.title == '烘烤记录') {
        this.tabIndex = 2
      } else if (to.meta.title == '历史记录') {
        this.tabIndex = 3
      }
      if(to.meta.title == '曲线烘烤'){
        this.tabIndex = 1
      }
    }
  },
  mounted() {
    console.log(this.$route.meta.title)
    if (this.$route.meta.title == '首页' || this.$route.meta.title == '故障列表' || this.$route.meta.title == '意见反馈') {
      this.flag = true
      this.flag1 = true
    } else {
      if (this.$route.meta.title == '系统设置' || this.$route.meta.title == '燃烧机参数' || this.$route.meta.title == '热泵机组参数' ||
        this.$route.meta.title == '其他设备参数' || this.$route.meta.title == '烘烤参数' || this.$route.meta.title == '历史记录') {
        this.flag1 = true
      } else {
        this.flag1 = false
      }
      this.flag = false
    }

    if (this.$route.meta.title == '实时图像') {
      this.tabIndex = 0
    } else if (this.$route.meta.title == '烘烤曲线') {
      this.tabIndex = 1
    } else if (this.$route.meta.title == '烘烤记录') {
      this.tabIndex = 2
    } else if (this.$route.meta.title == '历史记录') {
      this.tabIndex = 3
    }
    if (sessionStorage.getItem('deviceData')) {
      this.deviceData = JSON.parse(sessionStorage.getItem('deviceData'))
    }
    if (sessionStorage.getItem('userinfo')) {
      this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
    }
  },
  methods: {
    sumbitPwd() {
      this.request.post("/api/login/changePwd", {
        newpassword: this.newpassword,
        newpassword2: this.newpassword2
      }).then(res => {
        console.log(res)
        if (res.code == 1) {
          sessionStorage.setItem('userinfo', JSON.stringify(''));
          sessionStorage.setItem('scoketIndex', '');
          this.$websocket.close();
          this.$message({
            message: '修改密码成功，请重新登录！',
            type: 'success'
          });
          this.$router.push('/')
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    closeBtn() {
      sessionStorage.setItem('userinfo', JSON.stringify(''));
      sessionStorage.setItem('login_flag', false);
      sessionStorage.setItem('scoketIndex', '');
      this.$websocket.close();
      this.$message({
        message: '退出成功！',
        type: 'success'
      });
      this.$router.push('/')
    },
    mouseOver1() {
      this.list_flag1 = true
    },
    mouseLeave1() {
      this.list_flag1 = false
    },
    mouseOver2() {
      this.list_flag2 = true
    },
    mouseLeave2() {
      this.list_flag2 = false
    },
    goPage(item, index) {
      this.$router.push(item.name)
      vmson.$emit('left_num', { name: item.name, pageName: item.pageName, subordinateName: item.subordinateName, index: index, num: 2 })
    },
    goHome() {
      this.$router.push({ path: 'home1' })
      vmson.$emit('left_num', { name: 'home1', pageName: '首页', subordinateName: '首页', index: 8, num: 0 })
    },
    tabBtn(index) {
      if (this.$route.meta.title == '实时图像' || this.$route.meta.title == '曲线烘烤' || this.$route.meta.title == '烘烤曲线' || this.$route.meta.title == '烘烤记录') {
        if (sessionStorage.getItem('leftType') == 2) {
          if (index == 0) {
            this.tabIndex = 0
            vmson.$emit('messageEvent', 0);
          } else if (index == 1) {
            this.tabIndex = 1
            vmson.$emit('messageEvent', 1);
            this.$router.push({ path: 'CurveBaking' })
            vmson.$emit('left_num', { name: 'CurveBaking', pageName: '曲线烘烤', subordinateName: '曲线烘烤', index: 2, num: 0 })
          } else if (index == 2) {
            this.tabIndex = 2
            this.$router.push({ path: 'BakingRecords1' })
            vmson.$emit('left_num', { name: 'BakingRecords1', pageName: '烘烤记录1', subordinateName: '烘烤记录1', index: 14, num: 0 })
          }
        } else {
          if (index == 0) {
            this.tabIndex = 0
            this.$router.push({ path: 'RealImage' })
            vmson.$emit('left_num', { name: 'RealImage', pageName: '自动烘烤', subordinateName: '自动烘烤', index: 1, num: 0 })
          } else if (index == 1) {
            this.tabIndex = 1
            this.$router.push({ path: 'AutomaticBaking' })
            vmson.$emit('left_num', { name: 'AutomaticBaking', pageName: '烘烤曲线', subordinateName: '烘烤曲线', index: 13, num: 0 })
          } else if (index == 2) {
            this.tabIndex = 2
            this.$router.push({ path: 'BakingCurve' })
            vmson.$emit('left_num', { name: 'BakingCurve', pageName: '烘烤记录', subordinateName: '烘烤记录', index: 14, num: 0 })
          }
        }
        return;
      }
      this.tabIndex = index
      // if (index == 0) {
      //   this.$router.push({ path: 'RealImage' })
      //   vmson.$emit('left_num', { name: 'RealImage', pageName: '实时图像', subordinateName: '实时图像', index: 12, num: 0 })
      // } else if (index == 1) {
      //   this.$router.push({ path: 'BakingCurve' })
      //   vmson.$emit('left_num', { name: 'BakingCurve', pageName: '烘烤曲线', subordinateName: '烘烤曲线', index: 13, num: 0 })
      // } else if (index == 2) {
      //   this.$router.push({ path: 'BakingRecords' })
      //   vmson.$emit('left_num', { name: 'BakingRecords', pageName: '烘烤记录', subordinateName: '烘烤记录', index: 14, num: 0 })
      // } else if (index == 3) {
      //   this.$router.push({ path: 'History' })
      //   vmson.$emit('left_num', { name: 'History', pageName: '历史记录', subordinateName: '历史记录', index: 15, num: 0 })
      // }
      // if (index == 0) {
      //   this.$router.push({ path: 'RealImage' })
      //   vmson.$emit('left_num', { name: 'RealImage', pageName: '实时图像', subordinateName: '实时图像', index: 12, num: 0 })
      // } else if (index == 1) {
      //   this.$router.push({ path: 'AutomaticBaking' })
      //   vmson.$emit('left_num', { name: 'AutomaticBaking', pageName: '烘烤曲线', subordinateName: '烘烤曲线', index: 13, num: 0 })
      // } else if (index == 2) {
      //   this.$router.push({ path: 'BakingCurve' })
      //   vmson.$emit('left_num', { name: 'BakingCurve', pageName: '烘烤记录', subordinateName: '烘烤记录', index: 14, num: 0 })
      // }
      //  else if (index == 3) {
      //   this.$router.push({ path: 'History' })
      //   vmson.$emit('left_num', { name: 'History', pageName: '历史记录', subordinateName: '历史记录', index: 15, num: 0 })
      // }
    }
  },
};
</script>

<style scoped lang="scss">
.right {
  overflow: hidden;
  height: 46px;
  // min-height: 100%;
  -webkit-transition: margin-left .28s;
  transition: margin-left .28s;
  width: calc(100vw - 60px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002;
  padding: 10px 30px;
  background: linear-gradient(270deg, #57626F 0%, #3B4759 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      cursor: pointer;
    }

    .breadcrumb {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      .breadcrumb_li {
        cursor: pointer;
      }
    }
  }

  .right_select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #fff;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      border-radius: 50%;
    }
  }

}

.right_ul {
  .right_li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    color: #606266;
    font-size: 16px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 10px;
    }
  }

  .right_li:hover {
    background: #5C78F1;
    color: #fff;
  }

}

.dialog_ul {
  .dialog_li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .dialog_li_label {
      width: 80px;
      padding-right: 20px;
      text-align: right;
    }

    .el-input {
      width: 300px;
    }
  }
}


.header_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  text-align: left;

  .header_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 14px;
      padding-left: 20px;
    }

    .header_w {
      font-size: 14px;
      text-align: left;
      padding-left: 20px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        padding-left: 0;
      }
    }
  }

  .header_btn {
    width: 104px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background: #8EB0FF;
    border-radius: 10px;
    margin-left: 30px;
    cursor: pointer;
  }

}

.tab {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .tab_li {
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-bottom: 10px;
    margin-left: 10px;
    cursor: pointer;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    margin-top: 10px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }

  .tab_li_active {
    border-bottom: 3px solid #8EB0FF;
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from './router/router'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import request from "./utils/request";
Vue.prototype.request = request

import store from './store/store'
Vue.prototype.$store = store
if(sessionStorage.getItem('userinfo')){
  let userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
  store.state.userInfo = userInfo
}
Vue.prototype.$baseUrl = 'https://yanye.yyhkai.com'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);

import websocket from './common/webSocket';
Vue.prototype.$websocket = websocket;

// if(sessionStorage.getItem('token')){
//   console.log(sessionStorage.getItem('token'))
//   request.defaults.headers['token'] = JSON.parse(sessionStorage.getItem('token'));
// }
// axios.defaults.headers.post['Content-Type'] = 'application/json';


// import AMapLoader from '@amap/amap-jsapi-loader';
// // 如果需要密钥
// window._AMapSecurityConfig = {
//     securityJsCode: '1d05a07066d999465be13012df6786d3' // 开发环境使用
// }
// AMapLoader.load({
//     key: "017ac7830099331ab295bdf9105fc43c",              // 申请好的Web端开发者Key，首次调用 load 时必填
//     version: "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
//     plugins: [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
//     AMapUI: {
//         // 是否加载 AMapUI，缺省不加载
//         version: '1.1', // AMapUI 缺省 1.1
//         plugins: [] // 需要加载的 AMapUI ui插件
//     },
//     Loca: {
//         // 是否加载 Loca， 缺省不加载
//         version: '2.0.0' // Loca 版本，缺省 1.3.2
//     }
// }).then((AMap) => {
//     // 挂载AMap
//     Vue.prototype.$AMap = AMap;
// });


new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
